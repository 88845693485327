// Hide Header on on scroll down
var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = $('#site-header > .eyebrow-nav').outerHeight();

$(window).scroll(function(){
    didScroll = true;
});

setInterval(function() {
    if (didScroll) {
        hasScrolled();
        didScroll = false;
    }
}, 100);

function hasScrolled() {
    var st = $(this).scrollTop();
    
    // Make sure they scroll more than delta
    if(Math.abs(lastScrollTop - st) <= delta)
        return;
    
    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight){
        // Scroll Down
        $('#site-header').removeClass('nav-down').removeClass('page-top').addClass('nav-up');
        //$('.wide-navigation').removeClass('nav-down').addClass('nav-up')
    } else {
        // Scroll Up
        if(st + $(window).height() < $(document).height()) {
            $('header').removeClass('nav-up').addClass('nav-down');
            //$('.wide-navigation').removeClass('nav-up').addClass('nav-down');
        }
    }
    
    // give us a class when at the top of the page
    if(st == 0) {
      $('#site-header').addClass('page-top');
    } else {
      // $('header').removeClass('page-top');
    }
    
    lastScrollTop = st;
  console.log(st);
}
