export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    $('.dropdown-trigger').click(function() {

      // Toggle the "is-active" class
      $('.dropdown').toggleClass('is-active');
      //$(".dropdown-content").toggleClass("is-active");

    });
    /* function openFirstPanel(){
      $('.accordion > dt:first-child').next().addClass('active').slideDown();
    } */

    function openSectionPanel() {
      $('.accordion > dt a[href^="/' + location.pathname.split('/')[1] + '"]').parent().next('dd').addClass('active').slideDown();
      $('.accordion > dt a[href^="/' + location.pathname.split('/')[1] + '"]').next().addClass('opened');
    }
    
    (function($) {
        
      var allPanels = $('.accordion > dd').hide();
      var allTargets = $('.accordion > dt a.toggle-target');
      
      //openFirstPanel();
      openSectionPanel();
        
      $('.accordion > dt > a.toggle-target').click(function() {
          var $this = $(this);
          var $target =  $this.parent().next();
          
        
          if($target.hasClass('active')){
            $target.removeClass('active').slideUp();
            $this.removeClass('opened'); 
          }else{
            allPanels.removeClass('active').slideUp();
            allTargets.removeClass('opened');
            $target.addClass('active').slideDown();
            $this.addClass('opened');
          }
          
        return false;
      });
    
    })(jQuery);
  },
};
